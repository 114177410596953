import React, { Fragment } from "react";

import Page from "lib/@cms/components/layout/Page";
import Feature from "lib/@cms/components/cms/Feature";
import Hero from "lib/@cms/components/pages/index/Hero";
import Products from "lib/@cms/components/pages/index/Products";
import Divider from "lib/@cms/components/primitive/Divider";

function IndexPage() {
  return (
    <Page cms={{ pageId: "index" }} pathname="">
      {data => {
        return (
          <React.Fragment>
            <Hero variant={Hero.variant.CENTRAL} data={data.Heros} />
            <Divider className="tw-mb-20 md:tw-mb-40" />

            <div className="tw-container">
              <Products data={data.Products} />
              <Divider className="tw-mb-20 md:tw-mb-40" />

              {data.FeaturesV2?.map((feature, index) => {
                return (
                  <Fragment key={`FeatureV2-${index}`}>
                    <Feature variant={Feature.variant.V2} data={feature} />

                    <Divider className="tw-mb-20 md:tw-mb-48" />
                  </Fragment>
                );
              })}

              <Feature variant={Feature.variant.V1} data={data.FeatureV1} />
              <Divider className="tw-my-7 sm:tw-my-12" />
            </div>
          </React.Fragment>
        );
      }}
    </Page>
  );
}

export default IndexPage;
